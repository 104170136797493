import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Products from './components/Products';
import ProductAdmin from './components/ProductAdmin';
import LogIn from './components/auth/LogIn';
import Register from './components/auth/Register';
import ForgotPassword from './components/auth/ForgotPassword';
import CustomSetupTOTP from './components/auth/totp';
import ForgotPasswordVerification from './components/auth/ForgotPasswordVerification';
import ChangePassword from './components/auth/ChangePassword';
import ChangePasswordConfirm from './components/auth/ChangePasswordConfirm';
import Welcome from './components/auth/Welcome';
import Footer from './components/Footer';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import {Auth} from 'aws-amplify';
library.add(faEdit);

class App extends Component {
  state = {
    isAuthenticated:false,
    user:null,
    isAuthenticating:true,
    credentials:null
    
  };
  setAuthStatus = authenticated => {
    this.setState({isAuthenticated:authenticated});
  }
  setUser = user =>{
      this.setState({
        user:user
      });
  };

  setUserId = user_id=>{
    this.setState({user_id:user_id});
  };
  setCredentials = credentials=>{
    this.setState({credentials:credentials})
  }
  async componentDidMount(){
    try{
    const session = await Auth.currentSession();
    this.setAuthStatus(true);
    const credentials = await Auth.currentCredentials();
    // this.setState({credentials:credentials});
    this.setCredentials(credentials);
    console.log(session);
    const user = await Auth.currentAuthenticatedUser();
    const user_id = await Auth.currentUserInfo();
    this.setUser(user);
    this.setUserId(user_id);
    // this.setState({credentials:credentials})
    }catch(error)
    {
      console.log(error);

    }
    this.setState({isAuthenticating:false});

  }


  render() {

    const authProps= {
      isAuthenticated:this.state.isAuthenticated,
      user:this.state.user,
      setAuthStatus:this.setAuthStatus,
      setUser:this.setUser,
      setCredentials:this.setCredentials,
      credentials:this.state.credentials,
      user_id:this.state.user_id


    };
    return (
      !this.state.isAuthenticating && 
      <div className="App">
        <Router>
          <div>
            <Navbar auth={authProps}/>
            <Switch>
              <Route exact path="/" render={(props)=> <Home {...props} isauthenticatedtoggle={this.state.isAuthenticated} auth={authProps}/>} />
              <Route exact path="/login" render={(props)=> <LogIn {...props} auth={authProps}/>} />
              <Route exact path="/register" render={(props)=> <Register {...props} auth={authProps}/>} />
              <Route exact path="/forgotpassword" render={(props)=> <ForgotPassword {...props} auth={authProps}/>} />
              <Route exact path="/totp" render={(props)=> <CustomSetupTOTP {...props} auth={authProps} isauthenticatedtoggle={this.state.isAuthenticated}/>} />
              <Route exact path="/forgotpasswordverification" render={(props)=> <ForgotPasswordVerification {...props} auth={authProps}/>} />
              <Route exact path="/changepassword" render={(props)=> <ChangePassword {...props} auth={authProps}/>} />
              <Route exact path="/changepasswordconfirmation" render={(props)=> <ChangePasswordConfirm {...props} auth={authProps}/>} />
              <Route exact path="/welcome" render={(props)=> <Welcome {...props} auth={authProps}/>} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
