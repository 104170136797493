import React, { Fragment, Component } from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import AWS from 'aws-sdk';
// import {Dashboard} from '@uppy/dashboard'
// import Uppy from '@uppy/core'
// import {AwsS3Multipart} from '@uppy/aws-s3-multipart'
import { DashboardModal } from '@uppy/react'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { Auth } from 'aws-amplify';
import AwsS3MultipartHelper from './utility/aws_s3_multipart_helper'
import 'fs'
import axios from 'axios';
import question from '../icons8-question-mark-78.png';
import error from '../error.png';
import sync from '../sync.png';
import download from '../system_update_alt.png';
import { _ } from "gridjs-react";
import BootstrapTable from 'react-bootstrap-table-next';
import 'bootstrap/dist/css/bootstrap.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import  { dateFilter } from 'react-bootstrap-table2-filter';
import 'react-dates/initialize'; // for CSS
import 'react-dates/lib/css/_datepicker.css'; // for CSS
import { DateRangePicker } from 'react-dates';
import { useEffect } from "react";
import { useState } from 'react';
import  { textFilter } from 'react-bootstrap-table2-filter';
import moment from 'moment';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


// import { useHistory } from "react-router-dom";

// 
const AwsS3Multipart = require('@uppy/aws-s3-multipart')

var albumBucketName = "xtrodes-danila";
var BucketName_transformed = "xtrodes-danila-transformed";
var bucketRegion = "us-east-1";

var IdentityPoolId = "us-east-1:693df918-c3f5-4ebc-a47b-93e230c6b3df";



var s3 = null;
var s3_transformed = null;
const django_url = 'https://api-dev.xtrodes.com/'




var params = { Bucket: albumBucketName }


export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalOpenEDF:false,
      datasetname: null,
      previous_dataset:null,
      datasets: [],
      current_dataset_files: [],
      current_output_dataset_files: [],
      auth: props.auth,
      timer: null,
      split_status_timer: null,
      current_dataset_status: null,
      xtr1_status: null,
      xtr2_status: null,
      xf1_status: null,
      current_dataset_stage: null,
      current_dataset_progress: null, 
      current_dataset_processed_files: null,
      current_dataset_errors: null,
      show_split: false,
      split_history:[],
      split_status_timer:null,
      fixed_names_dict:{},
      fixed_names_zip:[],
      current_dataset_time:null,
      study_dict:{},
      current_study:null,
      show_move:false,
      recent_datasets: [[null,null],[null,null],[null,null],[null,null],[null,null],[null,null]],
      study_to_move:null,
      file_checkboxes:[],
      file_links:[],
      output_file_checkboxes:[],
      output_file_links:[],
      last_hrefs_raw:{},
      current_dataset_timestamp: null,
      last_launch_xf1_count: null,
      last_launch_xtr1_count: null,
      last_launch_xtr2_count: null,
      split_result:[],
      dataset_name_uid_match:{},
      file_uid_match : {},
      uid_dict: {},
      datasets_stats: {},

    }
    if (props.auth.isAuthenticated) {
      const options = {
        bucket: 'xtrodes-danila',
        acl: 'authenticated-read', // ACL you want to set for uploaded objects
        credentials: { // actual AWS credentials you want to use, we generate them using AWS STS GetFederationToken (https://docs.aws.amazon.com/STS/latest/APIReference/API_GetFederationToken.html)
          accessKeyId: props.auth.credentials.accessKeyId,
          secretAccessKey: props.auth.credentials.secretAccessKey,
          sessionToken: props.auth.credentials.sessionToken
        }
      };
      ;
    }
  }




  componentWillUnmount() {
    this.uppy.close()
  }

grid() {


 
  const cellStyle = {
    whiteSpace: 'normal',
    wordWrap: 'break-word' 
  };
  const  get_transformed_files = async(dataset,name) =>
  {
    try {
      console.error(this.state.auth)
      await this.renewMainSession()
      console.error(this.state.auth)
      console.error('trans')
      var key = null
      var href = "https://s3.amazonaws.com/"
        // var href = '';
        var bucketUrl = href + BucketName_transformed + "/"; 
      // List all objects in the S3 bucket with a given prefix
      const listObjectsParams = {
        Bucket: BucketName_transformed,
        Prefix: 'public/cognito/xtrodesclient/' + this.state.auth.user_id['id'] + '/' + dataset + '/',
        Delimiter: '/'
      };
      const listObjectsResponse = await s3.listObjectsV2(listObjectsParams).promise();
      
     if (listObjectsResponse.KeyCount > 0) {
      var key = null
      for (const object of listObjectsResponse.Contents) {
        if (object.Key.toLowerCase().endsWith('.edf')) {
          key =  object.Key;
        }
      }
      key = bucketUrl + key;
      key = this.downloadFile(BucketName_transformed,key,dataset,name)
      window.window.open(key, "_blank");
    } 
    } catch (error) {
      console.log(error);
    }
  
  }

  const  get_dataset_status = async(dataset,id) => {
   await this.renewMainSession()
    console.error('zxcxzcxz2',dataset)
    var that = this
    var d={}
    var url = django_url +  "get_session_status/" + id + '/';
     var optionAxios = {
       headers: {
         'Authorization': 'Bearer ' + this.state.auth.user.signInUserSession.accessToken["jwtToken"],
       }
     };
     axios
       .get(url, optionAxios)
       .then((response) => {
      var jsonData = JSON.parse(JSON.stringify(response.data));
      d = jsonData['xtr_status']
  const activeNestedDictionary = that.state.study_dict['default-.~111'][dataset]
  const updatedNestedDictionary = {
    ...activeNestedDictionary,
    edfstatus: d,
  };
  const updatedDictionary = {
    ...that.state.study_dict['default-.~111'],
    [dataset]: updatedNestedDictionary // Update the value of key2
  };
  const updatedDictionary2 = {
    ...that.state.study_dict,
    'default-.~111': updatedDictionary // Update the value of key2
  };
that.setState({ study_dict: updatedDictionary2 });
that.parse_sessions_in_state()

})

}

  function buttonFormatter (cell, row ) {
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        EDF could not be created <br/> Try to sync the data from the mobile app.<br/> If the problem persists, contact Xsupport@xtrodes.com.
      </Tooltip>
    );

          try{
            
            var key = null 
            var st =  row.edfstatus
            if (st=="no_launch") 
            {
              return('')
            }
            if (st=='ready') 
             {
              return(<button style={{border:"none", outline:"0",borderStyle: "none",backgroundColor: "white", textAlign: 'left' , padding:0, margin:0}}
                onClick={() =>  get_transformed_files(row.sessionname,row.name)}
              >
                 <img src={download}  width={24} height={24}/>
              </button>)
             }
            else if (st=="in_progress") 
            { return(<button style={{border:
              "none", outline:"0",borderStyle: "none",backgroundColor: "white", textAlign: 'left', padding:0, margin:0}}
              onClick={() => get_dataset_status(row.sessionname,row.id)}
            >
              <img src={sync} width={24} height={24} />
            </button>)
            }
            else {
              
              return(
                <div style={{textAlign: 'left'}}>
                <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
              <img src={error}   alt="Cant create"  data-toggle="tooltip" width={24} height={24}  />
              </OverlayTrigger>
              </div>)
            }
      }
      catch (error) {
      console.log(error);
      }}

      function getFilterOptions(st,field) {
        console.error(123)
        // get the unique quality values from the table data 
        const qualityValues = [...new Set(st.parse_sessions_in_state().map(p => p[field]))];
        
        // map the quality values to an options object
        const options = qualityValues.map(q => ({ value: q, label: q }));
        
        // return the options object
        return options;
      }
      
 function statusFormatter (cell, row )  {
        try{
          var key = null
          var st = row.edfstatus
          if (st=="no_launch") 
            {
              return "No EDF generation launched"
            }
          if (st=='ready') 
           {
            return(<span>ready</span>)
           }
          else if (st=="in_progress") 
          { return(<span >In progress</span>)
          }
          else {
            return(<span>Not available</span>)
          }
    }
    catch (error) {
    console.log(error);
    }
  }

  function dateFormatter (cell, row )  {
    return cell.toLocaleDateString()
    
} 

const DateRangePickerFilter = ({ column, onFilter, onFocusChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  const handleDateChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);

    if (startDate && endDate) {
      const filterVal = `${moment(startDate).format('YYYY-MM-DD')},${moment(endDate).format('YYYY-MM-DD')}`;
      onFilter(filterVal);
    } else {
      onFilter('');
    }
  };

  return (
    <span onClick={(e) => e.stopPropagation()}>
    <DateRangePicker
      startDate={ startDate }
      startDateId="start_date_id"
      endDate={ endDate }
      endDateId="end_date_id"
      onDatesChange={ handleDateChange }
      onFocusChange={ setFocusedInput }
      focusedInput={ focusedInput }
      displayFormat="YYYY-MM-DD"
      isOutsideRange={ () => false }
      showClearDates={true}
      minimumNights={0}
      small
    />
    </span>
  );
};

  const columns = [
          { dataField: "name", text: "Session Name",sort: true, 
          style: cellStyle,filter: textFilter()
           },
          { dataField: "sessionname", text: "Session Name",hidden: true ,sort: true,searchable: false
          },
          { dataField: "date", text: "Date" ,sort: true,searchable: false,
          filter: textFilter({
            onFilter: (filterVal, data) => {
              const [startDate, endDate] = filterVal.split(',');
              return data.filter((row) => {
                const rowDate = moment(row.date, 'YYYY-MM-DD');
                return (startDate && endDate) ? rowDate.isBetween(startDate, endDate, 'day', '[]') : true;
              });
            }}),formatter: dateFormatter, filterRenderer: (onFilter, column) => (
            <DateRangePickerFilter
            column={ column }
            onFilter={ onFilter }
            onFocusChange={ () => {} }
          />
          ),  },
          { dataField: "time", text: "Time",sort: true ,searchable: false },
          { dataField: "device", text: "Device",sort: true ,searchable: false,filter: selectFilter({
            options: getFilterOptions(this,'device') // dynamically generated options function
          }) },
          { dataField: "testtype", text: "Test type",sort: true ,searchable: false,filter: selectFilter({
            options: getFilterOptions(this,'testtype') // dynamically generated options function
          })},
          { dataField: "edfstatus", text: "EDF Status", formatter: statusFormatter,sort: true,searchable: false },
          { dataField: "download", text: "",formatter: buttonFormatter,searchable: false},
          { dataField: "id", text: "id",hidden: true ,searchable: false},
          
        ];

        const defaultSorted = [{
          dataField: 'date',
          order: 'desc'
        }];

        return (
          <div>
           
           
           <ToolkitProvider
           keyField='id'
           data={this.parse_sessions_in_state()} columns={ columns }  
          
      >
        { props => (
          <div>
        <BootstrapTable 
        { ...props.baseProps }
        filter={ filterFactory()}
        bootstrap4
        cellStyle={cellStyle}
        headerClasses='auto-width'
        defaultSorted={ defaultSorted } 
          >
          
          </BootstrapTable>
          </div>
        )
      }
      </ToolkitProvider>
        </div>)
}
  
  downloadFile = (bucket, file_url,datase,name) => {

    let list = file_url.split(bucket + '/');
    var sp = file_url.split('-.~')[0].split('/').pop()
    var tmp  = file_url.split('/').pop()
    tmp = '_' + tmp.replace(sp+'_', '')
    // var file = require('fs').createWriteStream('./test.test');
    // s3_transformed.getObject({Bucket:bucket,Key:list[1]}).createReadStream().pipe(file);

    var params = { Bucket: bucket, Key: list[1], ResponseContentDisposition:'attachment; filename ="' + name  
    + tmp + '"'};
    var url = null;

      url = s3.getSignedUrl('getObject', params);
    

    // console.log('The URL is', url);
    this.state.last_hrefs_raw[file_url] = url;
    var string = '<a href="' +url+'">download</a>'
    //url = html(string)
    return url; 
     }

  async componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      AWS.config.update({
        region: bucketRegion,
        credentials: this.props.auth.credentials
      });
      this.docClient  = new AWS.DynamoDB.DocumentClient();

      var params = { Bucket: albumBucketName }

      s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: params
      });
      var params_transformed = { Bucket: BucketName_transformed }
      s3_transformed = new AWS.S3({
        apiVersion: "2006-03-01",
        params: params_transformed
      });
      // AWS.config.update({region: bucketRegion,
      //   credentials: this.props.auth.credentials});
      //this.listAlbums();
      this.listAlbums2();
      // this.props.auth.user
      // const response = await this.ListDataSets();
      // this.setState({data:response});

      // const response = await fetch(`https://api.coinmarketcap.com/v1/ticker/?limit=10`);
      // const json = await response.json();
      // this.setState({ data: json });
    }
    else {
      this.setState({ datasets: null })
    }
  }




      


  renewMainSession = async () => {
   // const session = await Auth.currentSession();
    const credentials = await Auth.currentCredentials();
    this.state.auth.setCredentials(credentials);
    this.docClient  = new AWS.DynamoDB.DocumentClient();

    AWS.config.update({
      // region: bucketRegion,
      credentials: credentials
    });
    var params = { Bucket: albumBucketName }

    s3 = new AWS.S3({
      apiVersion: "2006-03-01",
      params: params
    });
    var params_transformed = { Bucket: BucketName_transformed }
    s3_transformed = new AWS.S3({
      apiVersion: "2006-03-01",
      params: params_transformed
    });

  }


  
parse_sessions_in_state()
{
  const valuesArray = [];
  for (let key in this.state.study_dict['default-.~111']) {
    valuesArray.push(this.state.study_dict['default-.~111'][key]);
  }
  return valuesArray
}
  
  

  listAlbums2 = () =>{
    var url = django_url +  "patientSessions/";
     var optionAxios = {
       headers: {
         'Authorization': 'Bearer ' + this.state.auth.user.signInUserSession.accessToken["jwtToken"],
       }
     };
     axios
       .get(url, optionAxios)
       .then((response) => {
        var jsonData = JSON.parse(JSON.stringify(response.data));
        var d = {} 
        var uid_d = {}
        var dataset_name_uid_dict ={}
        d['split']={}
        d['split']['split']={}
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
 
        for (var i = 0; i < jsonData.length; i++)
        {
            var study = jsonData[i];
            if (study.study_id != "default-.~111") {continue}
            if(!d[study.study_id]){
              d[study.study_id]=[];
          }
          for (var sess in study.sessions)
          {
            d[study.study_id][study.sessions[sess].Session_name] = {}
            const dateString = study.sessions[sess].il_session_start
            const date = new Date(dateString);
            const options = { timeZone: timeZone };
            const formattedDate  = date.toLocaleString('en-US', options);
            var testtype = ''
            if (study.sessions[sess].is_self_use == true)
               testtype = 'Home'
            else 
            testtype = 'Clinic'

          d[study.study_id][study.sessions[sess].Session_name] = {'name': study.sessions[sess].Dateset_name.split('-.~')[0],
                                                        'device':'BGX-' + study.sessions[sess].DAU_serial_number.slice(-5).replace(':',''),
                                                        'testtype':testtype,
                                                        'sessionname': study.sessions[sess].Session_name,
                                                        'id':study.sessions[sess].id,   
                                                        'date' :        new Date(formattedDate),
                                                        'time':    new Date(formattedDate).toLocaleTimeString(),
                                                        'errors': study.sessions[sess].generation_errors,
                                                        'edfstatus':     study.sessions[sess].xtr_status,                              
                                                      }
          //uid_d[study.sessions[sess].uuid] = study.sessions[sess].Dateset_name.split('-.~')[0]
          uid_d[study.sessions[sess].uuid] = study.sessions[sess].Session_name
          dataset_name_uid_dict[study.sessions[sess].uuid] = study.sessions[sess].Dateset_name.split('-.~')[0]

          }
        }
        var datasets_stats = {}

        
  
         console.error(111,datasets_stats)

        this.setState({ study_dict: d,uid_dict: uid_d, dataset_name_uid_match:dataset_name_uid_dict,datasets_stats:datasets_stats});
       })  
  }




 

  



  handleClick = () => this.setState({ count: 1 });




   isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
 

  render() {
    return this.props.isauthenticatedtoggle ? (<div><div className="mobile-alternate-message first-screen-message">Currently we do not support Mobile devices</div><div className="main-display-device">
      
      { "default-.~111" in this.state.study_dict ?(
        this.grid()
      //<Grid
      //
      //  data={this.parse_sessions_in_state()}
      //  columns={['name', 'device','testtype','efdstatus']}
      ///>
      ) : (null)}
      
      <p className='version'><small>Ver 2.2.0</small></p>
    </div></div>) : (<div><div className="mobile-alternate-message first-screen-message">Currently we do not support Mobile devices</div><div className="main-display-device"><div className='first-screen-message'>Welcome to X-trodes’ database website. This is the place to keep your recordings. We will take it from here. To proceed, please<a href="/login"> Log in </a>or<a href="/register"> Register</a></div></div></div>)
  }
};


