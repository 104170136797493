import AWS from 'aws-sdk';
import {Auth} from 'aws-amplify';


export default class AwsS3MultipartHelper {
  constructor(options) {
    this.options = {
      bucket: options.bucket,
      acl: options.acl || 'public-read',
      expires: options.expires || 60 * 60,
    };
    console.log('options.expires ' + this.options.expires);

    AWS.config.credentials = new AWS.Credentials({
      accessKeyId: options.credentials.accessKeyId,
      secretAccessKey: options.credentials.secretAccessKey,
      sessionToken: options.credentials.sessionToken,
    });
    AWS.config.httpOptions.timeout = 1200000;

    this.s3 = new AWS.S3({});
    setInterval(async ()=>{
      const session = await Auth.currentSession();
    // this.setAuthStatus(true);
    const credentials = await Auth.currentCredentials();
    AWS.config.update({
      // region: bucketRegion,
      credentials: credentials
    });
    this.s3=new AWS.S3({});
    },300000)
  }

  createMultipartUpload(file) {
    return new Promise((resolve, reject) => {
      const metadata = {};
      Object.keys(file.meta).map(key => {
        if (file.meta[key] != null) {
          console.error(metadata[key],key)
          metadata[key] = file.meta[key].toString()
        }
      })

      const key = file.name;
      
      this.s3.createMultipartUpload({        Bucket: this.options.bucket,
        Key: key,
        ACL: this.options.acl,
        ContentType: file.type,
        Metadata: metadata,
        Expires: this.options.expires,
      }, (error, data) => {
        if (error) {
          reject(error);
        } else {
          resolve({
            key: data.Key,
            uploadId: data.UploadId,
          });
        }
      });
    });
  }
  
  listParts(file, { key, uploadId }) {
    return new Promise((resolve, reject) => {
      let parts = [];
      
      const listPartsPart = (part) => {
        this.s3.listParts({
          Bucket: this.options.bucket,
          Key: key,
          UploadId: uploadId,
          PartNumberMarker: part,
        }, (error, data) => {
          if (error) {
            reject(error);
            return;
          }

          parts = parts.concat(data.Parts);
          if (data.IsTruncated) {
            listPartsPart(data.NextPartNumberMarker);
          } else {
            resolve(parts);
          }
        })
      }

      listPartsPart(0);
    });
  }
  
  prepareUploadPart(file, { key, uploadId, number }) {
    return new Promise((resolve, reject) => {
      this.s3.getSignedUrl('uploadPart', {
        Bucket: this.options.bucket,
        Key: key,
        UploadId: uploadId,
        PartNumber: number,
        Body: '',
        Expires: this.options.expires,
      }, (error, url) => {
        if (error) {
          reject(error);
        } else {
          resolve({ url });
        }
      });
    });
  }
  
  abortMultipartUpload(file, { key, uploadId }) {
    return new Promise((resolve, reject) => {
      this.s3.abortMultipartUpload({
        Bucket: this.options.bucket,
        Key: key,
        UploadId: uploadId
      }, (error, data) => {
        if (error) {
          reject(error);
        } else {
          resolve({});
        }
      });
    });
  }
  
  completeMultipartUpload(file, { key, uploadId, parts }) {
    return new Promise((resolve, reject) => {
      this.s3.completeMultipartUpload({
        Bucket: this.options.bucket,
        Key: key,
        UploadId: uploadId,
        MultipartUpload: {
          Parts: parts
        }
      }, (error, data) => {
        if (error) {
          reject(error);
        } else {
          resolve({ location: data.Location });
        }
      });
    });
  }
};