import React, { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import {Auth} from 'aws-amplify' //the {} import the module into the current scope. not sure exactly what it means
import showPwdImg from '../../view.svg';
import hidePwdImg from '../../hide.svg';
import QRCodeSVG from 'qrcode.react'
class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
    auth_code: "",
    hidden: true,
    auth: props.auth,
    errors: {
      cognito: null,
      blankfield: false
    },
    confirm: false,
    confirmation_code:"",
    otp_code :"",
    usrr: null
  };

  
  }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  };


  disbaleMFA = (user,) => {
    Auth.setPreferredMFA(user, 'NOMFA')
  .then((data) => {
    alert('Sucess')
    // ...
  })
  .catch((e) => {alert('Couldnt disable MFA')});
  }

  enableMFA = (user,) => {
 
    Auth.setupTOTP(user).then((code) => {
            const auth_code = "otpauth://totp/AWSCognito:"+ this.state.auth.user.username + "?secret=" + code + "&issuer=" + "Amazon Cognito";
            this.setState({ auth_code: auth_code });
            
    });
  }


  handleSubmit = (event) => {
    event.preventDefault();
    Auth.verifyTotpToken(this.state.auth.user, this.state.otp_code)
  .then(() => {
    Auth.setPreferredMFA(this.state.auth.user, 'TOTP');
    alert('Success')
  })
  .catch((e) => {
    console.error(e)
    alert ("Error while enabling MFA")
  });
  }

  render() {
    return this.props.isauthenticatedtoggle ? (
      
      <section className="section auth">
        <div><button className="button" onClick={() => this.disbaleMFA(this.state.auth.user)}>Disable MFA</button></div>
        <div><button className="button" onClick={() => this.enableMFA(this.state.auth.user)}>Enable MFA</button></div>
        {this.state.auth_code !== "" ? (<p style={{color:"white"}}>Please scan the qr code in your authenticator app and enter the code from the app below</p>): (null)}

        {this.state.auth_code !== "" ? ( <QRCodeSVG  value={this.state.auth_code} size={256} level='H' includeMargin={true} />): (null)}
        {this.state.auth_code !== "" ? ( 
          <form onSubmit={this.handleSubmit}>
          <input 
                    className="input" 
                    type="text"
                    id="otp_code"
                    aria-describedby="TOTP code"
                    placeholder="Enter TOTP code from the app"
                    value={this.state.otp_code}
                    onChange={this.onInputChange}
                  />
                  <button className="button is-success">
                  Submit
                </button>
          </form>
          
        ): (null)}
        
      </section>
    ) : (null)
  }
}

export default LogIn;