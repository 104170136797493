import React from 'react';

export default function Welcome() {
  return (
    <section className="section auth">
      <div className="container" style={{color:"white"}}>
        <h1>Welcome!</h1>
        <p>You have registered a new account.</p>
        <p>A verification link was sent to your Email.</p>
        <p>Please Login after your email is verified.</p>
      </div>
    </section>
  )
}
