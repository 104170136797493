import React, { Component } from 'react';
import FormErrors from "../FormErrors";
import Validate from "../utility/FormValidation";
import {Auth, AWSKinesisFirehoseProvider} from 'aws-amplify';

class ForgotPassword extends Component {
  state = {
    username: "",
    errors: {
      cognito: null,
      blankfield: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  }

  forgotPasswordHandler = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }



    
    // AWS Cognito integration here
    try{
        console.log(this.state.username);
        if (this.userExist(this.state.username)){
        let result = await Auth.forgotPassword(this.state.username);
        console.error(888,result)
        this.props.history.push('/forgotpasswordverification');
        }
        else {alert("This user doesnt exist")}
    }catch(error)
    {
      const code = error.name;
      switch (code) {
      case 'UserNotFoundException':
          alert("This user does not exist")
      }
    }
  }



  userExist(email) {
    return Auth.signIn(this.state.username, '123').then(res => {
        return false;
    }).catch(error => {
     const code = error.name;
     console.log(error);
     switch (code) {
         case 'UserNotFoundException':
          return false;
         case 'NotAuthorizedException':
          console.error(2)
             return true;
         case 'PasswordResetRequiredException':
          console.error(3)
           return !this.forgotPassword(email);
         case 'UserNotConfirmedException':
          console.error(4)
             return !this.redirectToCompleteRegister(email);
         default:
          console.error(6)
             return false;
     }
   });
 }

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {
    return (
      <section className="section auth">
        <div className="container">
          <h1 style=  {{color:"white"}}>Forgot your password?</h1>
          <p style={{color:"black"}}>
            Please enter the user name associated with your account and we'll
            email you a verification code to enter.
          </p>
          <FormErrors formerrors={this.state.errors} />

          <form onSubmit={this.forgotPasswordHandler}>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  type="text"
                  className="input"
                  type="text"
                  id="username"
                  aria-describedby="usernameHelp"
                  placeholder="Enter User Name"
                  value={this.state.username}
                  onChange={this.onInputChange}
                />

              </p>
            </div>
            <div className="field">
              <p className="control">
                <a href="/forgotpassword">Forgot password?</a>
              </p>
            </div>
            <div className="field">
              <p className="control">
                <button className="button is-success">
                  Submit
                </button>
              </p>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default ForgotPassword;